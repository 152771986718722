export const namespaced = true;

export const state = {
  eqData: null
};

export const mutations = {
  FILL_EQDATA(state, eqData) {
    state.eqData = {...eqData}
  },
  RESET_STATE() {
    state.eqData = null
  },
  UPDATE_EQUIP( state, equip) {
    let index = state.eqData.equipment.findIndex(unit => unit.id === equip.id)
    state.eqData.equipment[index] = { ...equip }   
  }
};

export const actions = {
  setEqData({ commit }, equipData) {
    if(equipData) {
      commit("FILL_EQDATA", equipData)
    } else {
      commit("RESET_STATE")
    }

  },
  updateEquip({ commit }, equip) {
    commit("UPDATE_EQUIP", equip)
  }
}

export const getters = {};
