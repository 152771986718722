<template>
  <v-app id="meterorganization">
    <v-main>
      <router-view />
      <Snackbar />
    </v-main>
  </v-app>
</template>

<script>
import Snackbar from "@/components/global/Snackbar.vue";
export default {
    metaInfo: {
    title: "Meter Organization"
  },
  components: {
    Snackbar
  }
};
</script>
