import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";
import DefaultLayout from "@/layout/DefaultLayout.vue";

Vue.use(VueRouter);
Vue.use(Meta);

const routes = [
  {
    path: "/",
    component: DefaultLayout,
    props: { title: "Billing Support", color: "#cf112d" },
    // eslint-disable-next-line no-unused-vars
    beforeEnter(to, from, next) {
      window.location.href =
        "https://www.sharp-sbs.com/Locations/North-Carolina";
    },
  },
  {
    path: "/billing",
    component: DefaultLayout,
    props: { title: "Billing Support", color: "#cf112d" },
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("@/views/Home"),
      },
    ],
  },
  {
    path: "/dmm",
    component: DefaultLayout,
    props: { title: "Meter Organization", color: "indigo" },
    children: [
      {
        path: "/premier",
        name: "Premier",
        component: () => import("@/views/Premier"),
      },
      {
        path: "/dmm",
        name: "Dmm",
        component: () => import("@/views/Dmm"),
      },
    ],
  },
  {
    path: "/fds",
    component: DefaultLayout,
    props: { title: "Funding Detail Sheet", color: "darkgrey" },
    children: [
      {
        path: "/fds",
        name: "FDS",
        component: () => import("@/views/Fds"),
      },
    ],
  },
  {
    path: "/dtalt",
    component: DefaultLayout,
    props: { title: "Delivery Ticket", color: "orange", version: "v1.0.7" },
    children: [
      {
        path: "/dtalt",
        name: "DTALT",
        component: () => import("@/views/Dtalt"),
      },
    ],
  },
  {
    path: "/cordt",
    component: DefaultLayout,
    props: { title: "Delivery Ticket", color: "green", version: "v1.0.0" },
    children: [
      {
        path: "/cordt",
        name: "CORDT",
        component: () => import("@/views/Cordt"),
      },
    ],
  },
  {
    path: "*",
    component: () => import("@/views/NotFound"),
    meta: {
      requiresAuth: false,
      requiresGuest: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  beforeEach: "",
  routes,
});

export default router;
