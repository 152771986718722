<template>
  <v-footer :color="color" app>
    <v-spacer></v-spacer>
    <p class="caption white--text">{{ version }}</p>
  </v-footer>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "indigo",
      required: true,
    },
    version: {
      type: String,
      default: "v0.2.1",
      required: false,
    },
  },
};
</script>
