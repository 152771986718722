<template>
      <v-container>
    <v-app-bar
      app
      :color="color"
      dark
    >
      <v-toolbar-title>{{ title }}</v-toolbar-title>

    </v-app-bar>
</v-container>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "Meter Organization",
      required: true
    },
    color: {
      type: String,
      default: "indigo",
      required: true
    }
  }


}
</script>
