<template>
  <v-app>
    <NavBar :title="title" :color="color" />
    <v-container>
      <router-view></router-view>
    </v-container>
    <Footer :color="color" :version="version" />
  </v-app>
</template>

<script>
import Footer from "@/components/Footer";
import NavBar from "@/components/NavBar";

export default {
  name: "meterorganization",
  components: {
    NavBar,
    Footer,
  },
  props: {
    title: {
      type: String,
      default: "Meter Organization",
      required: true,
    },
    color: {
      type: String,
      default: "indigo",
      required: true,
    },
    version: {
      type: String,
      default: "v0.0.1",
      required: false,
    },
  },
};
</script>
