export const namespaced = true;

export const state = {
  odData: null
};

export const mutations = {
  FILL_ODDATA(state, odData) {
    state.odData = { ...odData }
  },
  RESET_STATE() {
    state.odData = null
  }
};

export const actions = {
  setOdData({ commit }, odData) {
    if(odData) {
      commit("FILL_ODDATA", odData)
    } else {
      commit("RESET_STATE")
    }

  }
}

export const getters = {};
