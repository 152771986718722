export const namespaced = true;

export const state = {
    mono: [],
    color: []
  };
  
  export const mutations = {
    UPDATE_VALUES(state, rates) {
      state.mono = rates.mono
      state.color = rates.color
    },
    RESET_STATE(state) {
      state.mono = []
      state.color = []
    }
  };
  
  export const actions = {
    setserviceValues({ commit }, rates) {
      if(rates) {
        commit("UPDATE_VALUES", rates)
      } else {
        commit("RESET_STATE")
      }
    }
  }
  
  export const getters = {};