export const namespaced = true;

export const state = {
    computedValues: {
        startDate: new Date(),
        installDate: new Date(),
        daysDiff: 0,
        fdsNotes: null,
        transBill: true,
        dueDealer: null,
        transEquip: null,
        transMaint: null,
        transCust: null,
        serviceIncrease: true,
        leaseIncrease: false,
        escalateService: true,
        escalateRenewals: true,
        escalationRate: 10,
        leaseRate: null,
        leaseType: 'FMV',
        leaseSelect: { company: '', shortCode: '' },
        totalFunding: 0,
        totalValuation: 0,
        totalValPer: 0,
        addToMSRP: 0
    }
  };
  
  export const mutations = {
    UPDATE_VALUES(state, computedValues) {
      state.computedValues = {...computedValues}
    },
    RESET_STATE() {
      state.computedValues = {
        startDate: new Date(),
        installDate: new Date(),
        daysDiff: 0,
        fdsNotes: null,
        transBill: true,
        dueDealer: null,
        transEquip: null,
        transMaint: null,
        transCust: null,
        serviceIncrease: true,
        leaseIncrease: false,
        escalateService: true,
        escalateRenewals: true,        
        escalationRate: 10,
        leaseRate: null,
        leaseType: 'FMV',
        leaseSelect: { company: '', shortCode: '' },
        totalFunding: 0,
        totalValuation: 0,
        totalValPer: 0,
        addToMSRP: 0
      }
    }
  };
  
  export const actions = {
    setcomputedValues({ commit }, cv) {
      if(cv) {
        commit("UPDATE_VALUES", cv)
      } else {
        commit("RESET_STATE")
      }
    }

  }
  
  export const getters = {};