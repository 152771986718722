<template>
  <div name="snackbars">
    <v-snackbar
      v-model="showing"
      :color="color"
      :timeout="timeout"
      :bottom="'bottom'"
      :right="'right'"
    >
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="showing = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "snackbar/SHOW_MESSAGE") {
        this.text = state.snackbar.text;
        this.color = state.snackbar.color;
        this.timeout = state.snackbar.timeout;
        this.showing = true;
      }
    });
  },
  data() {
    return {
      showing: false,
      color: "",
      text: "",
      timeout: -1
    };
  }
};
</script>
