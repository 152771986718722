import Vue from "vue";
import Vuex from "vuex";

import * as equipment from "@/store/modules/equipment/equipData.js";
import * as orderdetails from "@/store/modules/orderdetails/odData.js";
import * as computed from "@/store/modules/computed/computed.js";
import * as maint from "@/store/modules/maintenance/service.js";

import * as snackbar from "@/store/modules/snackbar/snackbar.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    snackbar,
    equipment,
    orderdetails,
    computed,
    maint,
  },
  namespaced: true
});
